import React, {memo, useMemo} from 'react';

import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IMarking} from 'Dictionaries/models/IMarking';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {MergedGenders} from 'Common/constants/Gender';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import IndicatorField from 'Common/components/FormFields/IndicatorField';
import {Hint, MutableField} from 'Common/components/StyledComponents/StyledComponents';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import HorseHeightField from 'Common/components/FormFields/HorseHeightField';
import HorseMapField from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {AnimalType} from '../../../../Common/constants/AnimalType';

interface IProps {
  breeds: IBreed[];
  disciplines: IDiscipline[];
  colors: IHorseColor[];
  markings: IMarking[];
  animalType?: AnimalType;
}

const GeneralInformation = (props: IProps) => {
  const {disciplines, breeds, colors, markings, animalType} = props;

  return (
    <FormControlContainer title="General information">
      <MutableField width={50}>
        <InputField
          isRequired={true}
          name="name"
          type="text"
          label="Name"
          placeholder="Animal name"
          autoComplete="off"
          memoized={true}
        />
      </MutableField>
      <SelectField
        isMulti={true}
        name="disciplines"
        label="Discipline"
        options={castToOption(disciplines)}
        memoized={true}
      />
      <SelectField
        isMulti={true}
        name="breeds"
        label="Breeds"
        options={castToOption(breeds)}
        memoized={false}
        isRequired={true}
      />

      {animalType !== AnimalType.Dog && (
        <>
          <SelectField isMulti={true} name="colors" label="Colors" options={castToOption(colors)} memoized={true} />
          <SelectField
            isMulti={true}
            name="markings"
            label="Markings"
            options={castToOption(markings)}
            memoized={true}
          />
        </>
      )}

      <MutableField width={50}>
        <SelectField
          isRequired={true}
          name="gender"
          label="Sex"
          options={animalType && getStringKeysOption(MergedGenders[animalType])}
          memoized={true}
        />
      </MutableField>

      {animalType === AnimalType.Horse && (
        <>
          <HorseHeightField heightName="height" heightUnitName="heightUnit" />
          <HorseMapField />
          <FieldControlContainer label="Temperament" style={{marginTop: 16}}>
            <Hint style={{fontSize: 12}}>Rate temperament of your animal from 1 to 10</Hint>
            <MutableField width={75}>
              <IndicatorField name="temperament" showBottomLabels={true} isResetable={true} memoized={true} />
            </MutableField>
          </FieldControlContainer>
        </>
      )}

      <MutableField width={50}>
        <DateTimeField name="dateOfBirth" label="Date of birth" memoized={true} />
      </MutableField>
    </FormControlContainer>
  );
};

export default memo(GeneralInformation);

import React, {useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {UpdateOrderContext} from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import HorseGallery from 'Admin/AdminDashboard/components/shared/HorseGallery/HorseGallery';
import {Details, OrderHistory, RecordDetails} from 'Admin/AdminDashboard/components/shared/index';
import {AdminHorsesModule} from 'Admin/AdminDashboard/store/adminHorses/adminHorsesModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorses/index';
import ColorPalette from 'Common/constants/ColorPalette';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {IconName} from 'Icon/components/Icon';
import Loading from 'Loading/components/Loading';
import HorseReadinessToBah from './parts/HorseReadinessToBah';
import HorseSampleId from './parts/HorseSampleId';
import HorseVisibleGeneticData from './parts/HorseVisibleGeneticData';
import {AnimalType} from 'Admin/AdminDashboard/components/Horses/Horses';

const Column = RecordDetails.Column;

interface IExternalProps {
  sampleId?: number;
  horseId: number;
  ownerId: number;
  onEdit(id: number): void;
  onEditSampleId(horseId: number, id?: number): void;
  onSuccess?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

function HorseDetails(props: AllProps) {
  const {
    horseLoading,
    getHorse,
    resetHorseDetails,
    onEdit,
    onEditSampleId,
    horse,
    horseId,
    sampleId,
    ownerId,
    onSuccess,
  } = props;

  useEffect(() => {
    getHorse(horseId);
  }, [horseId, getHorse]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetHorseDetails, []);

  const reloadHorse = useCallback(() => getHorse(horseId), [horseId, getHorse]);
  const handleEditSampleId = useCallback(() => {
    onEditSampleId(horseId, sampleId);
  }, [horseId, onEditSampleId, sampleId]);

  const reloadOnSuccess = useCallback(() => {
    reloadHorse();
    onSuccess && onSuccess();
  }, [onSuccess, reloadHorse]);

  if (horseLoading.isRequesting) {
    return <Loading />;
  }

  if (horseLoading.error) {
    return <span>Error on order details loading</span>;
  }

  if (!horse || horse.id !== horseId) {
    return null;
  }

  const {
    id,
    disciplines,
    breeds,
    colors,
    markings,
    gender,
    orders,
    avatar,
    availabilityCriteria,
    isGeneticDataHidden,
    sample,
    animalType,
  } = horse!;

  const details = [
    {title: '', value: <HorseVisibleGeneticData isGeneticDataHidden={isGeneticDataHidden} />},
    {title: 'Sex', value: gender ?? ''},
    {title: 'Bah access', value: <HorseReadinessToBah criteria={availabilityCriteria} />},
    {
      title: 'Sample ID',
      value: <HorseSampleId id={sample?.id} sampleId={sample?.sampleId} onEditClick={handleEditSampleId} />,
    },
    {title: 'Discipline', value: disciplines?.[0]?.name},
    {title: 'Breeds', value: breeds?.map((e) => e.name)},
    {title: 'Color', value: colors?.map((e) => e.name)},
    {title: 'Markings', value: markings?.map((e) => e.name)},
  ];

  const detailsMenu = {
    items: [{label: 'Edit horse', value: 'edit', icon: {name: IconName.Edit, color: ColorPalette.red7}}],
    onSelect: () => onEdit(id),
  };

  return (
    <UpdateOrderContext.Provider value={{onUpdateOrder: reloadHorse}}>
      <RecordDetails>
        <Column label="Details" width="22%" menu={detailsMenu}>
          <Details records={details} />
        </Column>
        <Column label="Media" width="35%">
          <HorseGallery horseId={id} avatar={avatar} onSuccess={reloadOnSuccess} />
        </Column>
        <Column label="Orders Information" width="35%">
          <OrderHistory horseId={id} userId={ownerId} orders={orders} onSuccess={reloadHorse} />
        </Column>
      </RecordDetails>
    </UpdateOrderContext.Provider>
  );
}
const mapStateToProps = (state: IAppState) => ({
  horse: selectors.selectShortHorse(state),
  horseLoading: selectors.selectCommunication(state, 'shortHorseLoading'),
});

const mapDispatchToProps = {
  getHorse: actions.getShortHorse,
  resetHorseDetails: actions.resetHorseDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(HorseDetails));

export default withDynamicModules(Connected, AdminHorsesModule);
